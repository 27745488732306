import { FC, useCallback, useEffect } from 'react';
import {
  ActivityIcon,
  CalendarIcon,
  CloseIcon,
  ExclamationIcon,
  FileIcon,
  RepeteIcon,
  StaffIcon,
  TagIcon,
  TickIcon,
  UserIcon,
} from '@tapestry/shared/icons';
import { Controller, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import {
  ErrorBoundary,
  QueryClientProvider,
  RESTClient,
} from '@tapestry/shared/client';
import {
  GetTaskDocument,
  IPriorityLevelEnum,
  ITaskOccurrenceFrequencyEnum,
  ITaskStatusEnum,
  useAddTaskAttachment,
  useGetTaskLazyQuery,
  useRemoveTaskAttachment,
  useUpdateTask,
} from '@tapestry/shared/graphql';
import {
  Avatar,
  Card,
  FormDateTimePicker,
  FormInputBase,
  FormTextArea,
  FormTextInput,
  ListItem,
  Pill,
  TwistListItem,
  useAppMediaQuery,
} from '@tapestry/weave';
import Skeleton from 'react-loading-skeleton';
import {
  EditPageAssigneesField,
  EditPageAssigneesFieldLoadingState,
} from '../../components/EditPageAssigneesField/AssigneesField';
import { WidgetBar } from '@tapestry/shared/components';
import { twMerge } from 'tailwind-merge';
import { useTasksPagesErrorToastsAndRedirect } from '../../hooks/use-edit-page-error-toasts-and-redirects';
import { AttachmentsList } from '../../components/AttachmentsList';
import { transformTaskAttachmentsToAttachmentListItems } from '../../utils/attachment';
import { UpdateTaskForm } from '../../types';
import { getDefaultValuesFromTaskData } from '../../utils/get-default-values-from-task';
import { IAttachment, IsoString } from '@tapestry/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { TaskPriority } from '../../components/TaskPriority';
import { TaskStatusToggleButton } from '../../components/TaskStatusToggleButton';
import { schema } from '../../schema';
import { renderTaskFormFieldIcon } from '../../utils/render-form-filed-icon';
import { POLLING_INTERVAL } from '../../constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DueDateRepeat } from '../../components/DueDateRepeat';
import { getDueDateMeta } from '../../utils/get-due-date-meta-data';
import { getCurrentAppInfo, getPageTitle } from '@tapestry/shared/utils';
import Head from 'next/head';
import { transformUpdateTaskFormDataToAPIFields } from './utils/transformers';
import { Tags } from '../../components/Tags';
import { NetworkStatus } from '@apollo/client';
import { ThreadActivityList } from '../../components/ThreadActivityList/ThreadActivityList';

type ControllerEventCallback = {
  type: string;
  target?: { name: keyof UpdateTaskForm; value: unknown };
};

// TODO[style]: rename functions and move to a separate file
export const updateRecurrencyIfNecessary = (
  value: IsoString | null,
  occurrence_manager: UpdateTaskForm['occurrence_manager']
) => {
  if (!occurrence_manager) {
    return null;
  }

  if (!value) {
    return { ...occurrence_manager, active: false };
  }

  // if the recurrency is monthly on, say, the second friday.
  // if you move the due date to thursday, the recurrency must now repeat on thursday
  if (
    occurrence_manager.frequency === ITaskOccurrenceFrequencyEnum.Monthly &&
    occurrence_manager.rules &&
    occurrence_manager.active
  ) {
    const { dueWeekDay, nthWeekday } = getDueDateMeta(value);

    return {
      ...occurrence_manager,
      rules: {
        weekday: [dueWeekDay],
        nthWeekday: [nthWeekday],
      },
    };
  }

  return occurrence_manager;
};

const { appInfo } = getCurrentAppInfo();

export const EditTaskPage: FC = () => {
  const {
    repeatDueDateField: hasDueDateFlag,
    taskAppletTagsField: hasTagsFieldFlag,
    taskActivity: hasActivityFlag,
  } = useFlags();
  const router = useRouter();
  const { isPhone } = useAppMediaQuery();
  const callbackSequences = useTasksPagesErrorToastsAndRedirect();

  const taskId = router.query.taskId as string;

  const {
    register,
    getValues,
    formState: { errors, isDirty, defaultValues },
    reset,
    control,
    watch,
    trigger,
  } = useForm<UpdateTaskForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: async () => {
      const { data } = await getTask();

      if (!data?.getTask) {
        callbackSequences.onTaskError();
      }

      return getDefaultValuesFromTaskData(data?.getTask);
    },
  });

  const [getTask, { data, startPolling, stopPolling, networkStatus }] =
    useGetTaskLazyQuery({
      variables: { taskId },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        const updatedFormData = getDefaultValuesFromTaskData(data?.getTask);

        reset(updatedFormData, { keepDirtyValues: true });
      },
      onError: callbackSequences.onTaskError,
    });
  const task = data?.getTask;
  const owner = task?.owner;
  const canEdit = owner?.isMe || false;
  const attachments = transformTaskAttachmentsToAttachmentListItems(
    task?.attachments ?? []
  );
  const assignments = task?.assignments ?? [];
  const requireResolution = task?.require_resolution ?? false;
  const currentUserAssignment = assignments.find(
    (assignment) => assignment.assignee.isMe
  );

  const isInitialLoading = networkStatus === NetworkStatus.loading;

  const taskTitle = watch('title');
  const dueDate = watch('due_date');
  const isHightPriorityTask = watch('priority') === IPriorityLevelEnum.Highest;

  const [updateTask] = useUpdateTask({
    onCompleted: callbackSequences.onUpdateTaskSuccess({
      reset,
    }),
    onError: callbackSequences.onUpdateTaskError,
    refetchQueries: [{ query: GetTaskDocument, variables: { taskId } }],
  });

  const [addAttachment] = useAddTaskAttachment({
    onCompleted: callbackSequences.onUpdateTaskSuccess({
      message: 'New attachment added successfully.',
    }),
    onError: callbackSequences.onUpdateTaskError,
    refetchQueries: [{ query: GetTaskDocument, variables: { taskId } }],
  });

  const [removeTaskAttachment] = useRemoveTaskAttachment({
    onCompleted: callbackSequences.onUpdateTaskSuccess({
      message: 'Attachment is removed successfully.',
    }),
    onError: callbackSequences.onUpdateTaskError,
    refetchQueries: [{ query: GetTaskDocument, variables: { taskId } }],
  });

  const handleInlineUpdate = async ({ target }: ControllerEventCallback) => {
    if (!target?.name) {
      return;
    }

    const isValid = await trigger(target.name);

    if (!isValid) {
      return;
    }

    const formData = getValues();
    const updatedFormData = {
      ...formData,
      [target.name]: target.value,
    };

    if (target.name === 'due_date') {
      updatedFormData.occurrence_manager = updateRecurrencyIfNecessary(
        target.value as UpdateTaskForm['due_date'],
        formData.occurrence_manager
      );
    }

    const transformedFiels = transformUpdateTaskFormDataToAPIFields(
      updatedFormData,
      defaultValues
    );

    updateTask({
      variables: {
        updateTaskId: taskId,
        fields: transformedFiels,
      },
    });
  };

  const handleUpdateOnBlur = async ({ target }: ControllerEventCallback) => {
    if (!target?.name) {
      return;
    }

    const isValid = await trigger(target.name);

    if (!isValid || !isDirty) {
      return;
    }

    const formData = getValues();

    updateTask({
      variables: {
        updateTaskId: taskId,
        fields: transformUpdateTaskFormDataToAPIFields(formData),
      },
    });
  };

  const pageTitle = getPageTitle(appInfo.title, 'Tasks', 'Update', taskTitle);

  const handleUploadComplete = (attachment: IAttachment) => {
    addAttachment({
      variables: {
        taskId,
        attachment: {
          file_name: attachment.title,
          file_path: attachment.url,
          file_type: attachment.file_mime,
        },
      },
    });
  };

  const handleRemoveAttachment = (attachmentId: string) => {
    removeTaskAttachment({
      variables: {
        taskId,
        attachmentId,
      },
    });
  };

  const handleToggleRequireResolution = (isSelected: boolean) => {
    const formData = getValues();

    const updatedFields = {
      ...transformUpdateTaskFormDataToAPIFields(formData),
      require_resolution: isSelected,
    };

    updateTask({
      variables: {
        updateTaskId: taskId,
        fields: updatedFields,
      },
    });
  };

  const handleRefreshTask = () => {
    getTask();
  };

  const handleRefetch = useCallback(
    (event) => {
      const visibilityState = !(event.target as Document).hidden;

      if (visibilityState) {
        startPolling(POLLING_INTERVAL);
      } else {
        stopPolling();
      }
    },
    [startPolling, stopPolling]
  );

  useEffect(
    function setDataPollingInterval() {
      window.addEventListener('visibilitychange', handleRefetch);
      // start pooling imediatly after the page get
      startPolling(POLLING_INTERVAL);

      return () => {
        window.removeEventListener('visibilitychange', handleRefetch);
        stopPolling();
      };
    },
    [handleRefetch, startPolling, stopPolling]
  );

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <ErrorBoundary onError={callbackSequences.onTaskError}>
        <main className="px-4">
          <div className="mx-auto mb-32 mt-4 w-full max-w-screen-xl rounded-lg bg-white px-4 py-4 sm:p-10 sm:pt-8">
            <div
              className={twMerge(
                'mb-4 flex items-center',
                canEdit || isHightPriorityTask
                  ? 'justify-between'
                  : 'justify-end'
              )}
            >
              {canEdit ? (
                <h1 className="text-2xl font-bold">Update Task</h1>
              ) : null}

              {isHightPriorityTask && !canEdit ? (
                <Pill
                  label="important"
                  bgColor="bg-red-light"
                  textColor="text-red"
                />
              ) : null}

              <button
                type="button"
                title="Return to tasks"
                aria-label="Return to tasks"
                className="hover:bg-gray-hover focus:bg-gray-hover z-10 inline-flex size-8 items-center   justify-center rounded-full p-1 disabled:cursor-wait sm:size-6"
                onClick={router.back}
                onKeyUp={({ key }) => {
                  if (key === 'Enter') {
                    router.back();
                  }
                }}
              >
                <p className="sr-only">return to tasks</p>
                <CloseIcon light />
              </button>
            </div>

            <form className="relative">
              <div
                className={twMerge(
                  'absolute right-0 z-10 hidden md:inline',
                  canEdit && '-top-2'
                )}
              >
                <TaskStatusToggleButton
                  taskId={taskId}
                  isOwner={canEdit}
                  requireResolution={requireResolution}
                  taskStatus={task?.status ?? ITaskStatusEnum.Pending}
                  currentUserAssignment={currentUserAssignment}
                  variant="text"
                  onTaskStatusUpdateComplete={handleRefreshTask}
                  onTaskAssigmentStatusUpdateComplete={handleRefreshTask}
                />
              </div>

              <ul className="list-none sm:mt-6">
                <TwistListItem
                  icon={<TickIcon fillColor="#fff" />}
                  iconBackgroundColor="bg-pink"
                >
                  <div className="flex flex-col gap-2">
                    {canEdit ? (
                      <>
                        <FormTextInput
                          {...register('title', {
                            onBlur: handleUpdateOnBlur,
                          })}
                          label="Task Name"
                          placeholder="Update your task name"
                          aria-invalid={errors.title ? 'true' : 'false'}
                          error={errors.title?.message}
                          disabled={!canEdit}
                          icon={renderTaskFormFieldIcon(
                            <TickIcon fillColor="#fff" />,
                            isPhone
                          )}
                        />
                        <Controller
                          control={control}
                          name="priority"
                          rules={{
                            onChange: handleInlineUpdate,
                          }}
                          render={({ field: { value, onChange } }) => (
                            <TaskPriority
                              disabled={!canEdit}
                              priority={value ?? IPriorityLevelEnum.Medium}
                              onChange={onChange}
                            />
                          )}
                        />
                      </>
                    ) : (
                      <>
                        <h1 className="mr-8 text-base font-bold leading-normal sm:mr-10 md:hidden">
                          {defaultValues?.title}
                        </h1>

                        <div className="hidden md:block">
                          <FormInputBase label="Task Name" name="title">
                            <h1
                              id="title"
                              className="text-base font-bold leading-normal md:text-lg"
                            >
                              {isInitialLoading ? (
                                <Skeleton width={150} />
                              ) : (
                                defaultValues?.title
                              )}
                            </h1>
                          </FormInputBase>
                        </div>
                      </>
                    )}
                  </div>
                </TwistListItem>
                <TwistListItem
                  icon={<ExclamationIcon fillColor="#fff" />}
                  iconBackgroundColor="bg-pink"
                >
                  {canEdit ? (
                    <FormTextArea
                      {...register('description', {
                        onBlur: handleUpdateOnBlur,
                      })}
                      label="Information"
                      placeholder="e.g. description of the task"
                      aria-invalid={errors.description ? 'true' : 'false'}
                      error={errors.description?.message}
                      disabled={!canEdit}
                      icon={renderTaskFormFieldIcon(
                        <ExclamationIcon fillColor="#fff" />,
                        isPhone
                      )}
                    />
                  ) : (
                    <FormInputBase
                      label="Information"
                      name="description"
                      icon={renderTaskFormFieldIcon(
                        <ExclamationIcon fillColor="#fff" />,
                        isPhone
                      )}
                    >
                      <p>
                        {isInitialLoading ? (
                          <Skeleton count={3} />
                        ) : (
                          defaultValues?.description
                        )}
                      </p>
                    </FormInputBase>
                  )}
                </TwistListItem>
                <TwistListItem
                  icon={<StaffIcon fillColor="#fff" />}
                  iconBackgroundColor="bg-pink"
                >
                  {task ? (
                    <EditPageAssigneesField
                      assignments={assignments}
                      canEdit={canEdit}
                      taskId={taskId}
                      requireResolution={requireResolution}
                      onRequireResolutionChange={handleToggleRequireResolution}
                    />
                  ) : (
                    <EditPageAssigneesFieldLoadingState />
                  )}
                </TwistListItem>

                {hasTagsFieldFlag ? (
                  <TwistListItem
                    icon={<TagIcon fillColor="#fff" />}
                    iconBackgroundColor="bg-pink"
                    showStrip
                  >
                    <Controller
                      control={control}
                      name="tags"
                      rules={{
                        onChange: handleInlineUpdate,
                      }}
                      render={({ field: { value, onChange } }) => (
                        <Tags
                          tags={value ?? []}
                          editable={canEdit}
                          onChange={onChange}
                        />
                      )}
                    />
                  </TwistListItem>
                ) : null}
                <TwistListItem
                  icon={<CalendarIcon fillColor="#fff" />}
                  iconBackgroundColor="bg-pink"
                  showStrip
                >
                  {defaultValues?.due_date || owner?.isMe ? (
                    <Controller
                      control={control}
                      name="due_date"
                      rules={{
                        onBlur: handleUpdateOnBlur,
                      }}
                      render={({
                        field: { ref: _, ...fieldWithoutRef },
                        fieldState: { error },
                      }) => (
                        <FormDateTimePicker
                          label="Due Date"
                          minDate={new Date()}
                          disabled={!owner?.isMe || !canEdit}
                          error={error?.message}
                          icon={renderTaskFormFieldIcon(
                            <CalendarIcon fillColor="#fff" />,
                            isPhone
                          )}
                          onDateSelection={(value: IsoString | null) =>
                            handleInlineUpdate({
                              type: 'onChange',
                              target: {
                                name: 'due_date',
                                value,
                              },
                            })
                          }
                          {...fieldWithoutRef}
                        />
                      )}
                    />
                  ) : (
                    <FormInputBase
                      label="Due Date"
                      name="due_date"
                      icon={renderTaskFormFieldIcon(
                        <CalendarIcon fillColor="#fff" />,
                        isPhone
                      )}
                    >
                      <Card
                        bgColor="bg-gray-lightest"
                        as="p"
                        className="text-gray-text"
                      >
                        No due date
                      </Card>
                    </FormInputBase>
                  )}
                </TwistListItem>

                {hasDueDateFlag && dueDate && (
                  <TwistListItem
                    icon={<RepeteIcon fillColor="#fff" />}
                    iconBackgroundColor="bg-pink"
                    showStrip
                  >
                    <Controller
                      control={control}
                      name="occurrence_manager"
                      rules={{
                        onChange: handleInlineUpdate,
                      }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <DueDateRepeat
                            dueDate={dueDate}
                            onChange={onChange}
                            rules={value}
                            disabled={!owner?.isMe || !canEdit}
                            error={{
                              end_date:
                                errors.occurrence_manager?.end_date?.message?.toString(),
                            }}
                          />
                        );
                      }}
                    />
                  </TwistListItem>
                )}

                <TwistListItem
                  icon={<FileIcon fillColor="#fff" />}
                  iconBackgroundColor="bg-pink"
                  showStrip
                >
                  <FormInputBase
                    label="File and image attachments"
                    name="attachments"
                    icon={renderTaskFormFieldIcon(
                      <FileIcon fillColor="#fff" />,
                      isPhone
                    )}
                  >
                    <AttachmentsList
                      isLoading={isInitialLoading}
                      isEditable={owner?.isMe ?? false}
                      attachments={attachments}
                      onUploadComplete={handleUploadComplete}
                      onRemoveComplete={handleRemoveAttachment}
                    />
                  </FormInputBase>
                </TwistListItem>
                <TwistListItem
                  icon={<UserIcon fillColor="#fff" />}
                  iconBackgroundColor="bg-pink"
                  showStrip={hasActivityFlag || false}
                >
                  <FormInputBase
                    label="Owned by"
                    name="owner"
                    icon={renderTaskFormFieldIcon(
                      <UserIcon fillColor="#fff" />,
                      isPhone
                    )}
                  >
                    <ListItem
                      className="w-full text-left"
                      borderColor="border-gray-200"
                    >
                      <Avatar src={owner?.photo_url} />
                      <div className="xs:ml-4 ml-2 truncate text-left">
                        <p className="font-semibold text-black">
                          {owner?.first_name} {owner?.last_name}
                        </p>
                      </div>
                    </ListItem>
                  </FormInputBase>
                </TwistListItem>

                {hasActivityFlag && (
                  <TwistListItem
                    icon={<ActivityIcon fillColor="#fff" />}
                    iconBackgroundColor="bg-pink"
                    showStrip={false}
                  >
                    <FormInputBase
                      label="Activity"
                      name="activity"
                      sublabel="See a history of activity related to this task"
                      icon={renderTaskFormFieldIcon(
                        <ActivityIcon fillColor="#fff" />,
                        isPhone
                      )}
                    >
                      <ErrorBoundary>
                        <QueryClientProvider client={RESTClient}>
                          <ThreadActivityList threadId={taskId} />
                        </QueryClientProvider>
                      </ErrorBoundary>
                    </FormInputBase>
                  </TwistListItem>
                )}
              </ul>
            </form>
          </div>
        </main>

        <WidgetBar hide="md:hidden">
          <TaskStatusToggleButton
            taskId={taskId}
            isOwner={canEdit}
            requireResolution={requireResolution}
            taskStatus={task?.status ?? ITaskStatusEnum.Pending}
            currentUserAssignment={currentUserAssignment}
            className="size-15 grow-on-hover p-3 shadow-lg transition-all duration-500 ease-in-out"
            onTaskStatusUpdateComplete={handleRefreshTask}
            onTaskAssigmentStatusUpdateComplete={handleRefreshTask}
          />
        </WidgetBar>
      </ErrorBoundary>
    </>
  );
};
