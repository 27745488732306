import {
  QueryFunction,
  skipToken,
  useInfiniteQuery,
  useQuery,
} from '@tapestry/shared/client';
import ActivityService from './domain/activity-service';
import { useWithPagination } from './api/use-with-pagination';
import { Activity } from './domain/activity';
import { QueryPagination } from './api/api';

export const useGetCurrentUserActivities = () => {
  const [page, withPagination] = useWithPagination();

  let userId: string | null = null;

  if (typeof window.localStorage !== 'undefined') {
    userId = window.localStorage.getItem('userId');
  }

  const query = useQuery({
    queryKey: ['current-user-activities', userId, page],
    queryFn: userId
      ? () => ActivityService.getUserActivities(userId, { page })
      : skipToken,
  });

  return {
    ...query,
    userActivities: query.data?.activities || [],
    ...withPagination(query.data?.pagination),
  };
};

export const useGetThreadActivities = (threadId: string) => {
  const [page, withPagination] = useWithPagination();

  const query = useQuery({
    queryKey: ['thread-activities', threadId, page],
    queryFn: threadId
      ? () => ActivityService.getActivitiesByThreadId(threadId, { page })
      : skipToken,
  });

  return {
    ...query,
    activities: query.data?.activities || [],
    ...withPagination(query.data?.pagination),
  };
};

const getActivitiesByThreadIdInfinitly: QueryFunction<
  {
    activities: Activity[];
    pagination: QueryPagination;
  },
  string[],
  number
> = async ({ pageParam, queryKey }) => {
  const [_key, threadId] = queryKey;

  return await ActivityService.getActivitiesByThreadId(threadId, {
    page: pageParam,
  });
};

export const useGetThreadActivitiesInfinite = (threadId: string) => {
  const query = useInfiniteQuery({
    queryKey: ['thread-activities-infinite', threadId],
    queryFn: threadId ? getActivitiesByThreadIdInfinitly : skipToken,
    initialPageParam: 1,
    getNextPageParam: (lastData) => {
      const { pagination } = lastData;

      if (pagination.page === pagination.totalPages) {
        // no more to fetch
        return null;
      }

      return lastData.pagination.page + 1;
    },
  });

  return {
    ...query,
    activities: query?.data?.pages?.flatMap((page) => page.activities) || [],
  };
};
