import { axiosClient } from '@tapestry/shared/client';
import { ActivityDTO } from './activity-api-types';

export type QueryPagination = {
  page: number;
  limit: number;
  totalPages: number;
};

export type ActivitiesByUserIdParams = Partial<{
  page: number;
  limit: number;
}>;

export type ActivitiesByUserIdReturn = {
  data: ActivityDTO[];
  pagination: QueryPagination;
};

const fetchActivitiesByUserId = async (
  userId: string,
  params?: ActivitiesByUserIdParams
) => {
  let { data } = await axiosClient.get<ActivitiesByUserIdReturn>(
    `/ledger/activity/user/${userId}`,
    {
      params,
    }
  );

  return data;
};

export type ActivitiesByThreadIdParams = ActivitiesByUserIdParams;
export type ActivitiesByThreadIdReturn = {
  data: ActivityDTO[];
  pagination: QueryPagination;
};

const fetchActivitiesByThreadId = async (
  threadId: string,
  params?: ActivitiesByThreadIdParams
) => {
  let { data } = await axiosClient.get<ActivitiesByThreadIdReturn>(
    `/ledger/activity/task/${threadId}`,
    {
      params,
    }
  );

  return data;
};

export default { fetchActivitiesByUserId, fetchActivitiesByThreadId };
