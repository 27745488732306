import { IsoString } from '@tapestry/types';
import { ActivityAction, ActivityDTO } from '../api/activity-api-types';
import { Activity } from './activity';

const constructRedirectURL = (subject: ActivityDTO['subject']) => {
  let { redirect_type, redirect_id } = subject;

  return encodeURI(`/${redirect_type}/${redirect_id}`);
};

const mapDTOActivityTypeToDisplayWords = (
  activityType: ActivityAction
): [string, string] => {
  switch (activityType) {
    case 'tasks.create':
      return ['created a', 'task'];
    case 'tasks.update':
      return ['updated a', 'task'];
    case 'tasks.delete':
      return ['deleted a', 'task'];
    case 'tasks.attachment-add':
      return ['added an attachment to', 'task'];
    case 'tasks.status-update':
      return ['updated the status of a', 'task'];
    case 'tasks.attachment-delete':
      return ['deleted an attachment from a', 'task'];
    case 'tasks.status-updateAssignment':
      return ['updated the assignment status of a', 'task'];
    case 'tasks.assignee-update':
      return ['updated an assignee on a', 'task'];
    default:
      return ['did an action to', 'something'];
  }
};

export const dtoToActivity = (ActivityDTO: ActivityDTO): Activity => {
  const redirectURL = constructRedirectURL(ActivityDTO.subject);

  const [action, displayType] = mapDTOActivityTypeToDisplayWords(
    ActivityDTO.type as ActivityAction
  );

  return {
    id: ActivityDTO.id,
    // who
    actor: ActivityDTO.actor,
    // did something - (i.e. assigned a task)
    action,
    // related to this
    subject: {
      title: ActivityDTO.subject.title,
      redirectURL,
      displayType,
    },
    // to someone else
    receiver: ActivityDTO.receiver,
    // metadata
    datetime: ActivityDTO.datetime as IsoString, //TODO[high]: how to check that its IsoString?
  };
};
