import { FC, useEffect } from 'react';
import {
  CalendarIcon,
  CloseIcon,
  ExclamationIcon,
  FileIcon,
  PlusIcon,
  RepeteIcon,
  StaffIcon,
  TagIcon,
  TickIcon,
} from '@tapestry/shared/icons';
import { Controller, useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { ROUTE_PATHS } from '@tapestry/shared/constants';
import { useToast } from '@tapestry/shared/client';
import {
  GetTasksDocument,
  IPriorityLevelEnum,
  IUser,
  useCreateTask,
  useSearchUsers,
} from '@tapestry/shared/graphql';
import {
  Button,
  Checkbox,
  FormDateTimePicker,
  FormInputBase,
  FormTextArea,
  FormTextInput,
  TwistListItem,
  useAppMediaQuery,
} from '@tapestry/weave';
import { useModal } from '@tapestry/shared/hooks';
import isEmpty from 'lodash/isEmpty';
import { CreateTaskForm } from '../../types';
import { loadable } from '@tapestry/shared/lazy-load';
import { UserListItem } from '../../components/AssignToModal/UserListItem';
import { GET_TASKS_DEFAULT_VARIABLES } from '../../constants';
import { IAttachment } from '@tapestry/types';
import { AttachmentsList } from '../../components/AttachmentsList';
import { filterValidUsers } from '../../utils/filter-out-non-valid-user';
import { transformUploadResponseToAttachmentListItem } from '../../utils/attachment';
import { TaskPriority } from '../../components/TaskPriority';
import { renderTaskFormFieldIcon } from '../../utils/render-form-filed-icon';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from '../../schema';
import { DueDateRepeat } from '../../components/DueDateRepeat';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Tags } from '../../components/Tags';
import { WidgetBar } from '@tapestry/shared/components';
import { transformCreateTaskFormDataToAPIFields } from './utils/transformer';

const AssignStaffModal = loadable(
  () => import('../../components/AssignToModal/AssignToModal'),
  { chunkName: 'add-staff-modal', ssr: false }
);

const CREATE_TASK_FORM_DEFAULT_VALUES: Partial<CreateTaskForm> = {
  task_type: 'retail_insight',
  description: null,
  assignments: [],
  attachments: [],
  due_date: null,
  priority: null,
  status: null,
  require_resolution: false,
  occurrence_manager: null,
  tags: [],
};

export const CreateTaskPage: FC = () => {
  const {
    repeatDueDateField: hasDueDateFlag,
    taskAppletTagsField: hasTagsFieldFlag,
  } = useFlags();
  const router = useRouter();
  const { addToast } = useToast();
  const { isPhone } = useAppMediaQuery();
  const assignStaffModal = useModal();
  // * Prefetch modal data
  useSearchUsers({ variables: { page: 1 } });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    setValue,
    watch,
    control,
  } = useForm<CreateTaskForm>({
    resolver: yupResolver(schema),
    defaultValues: CREATE_TASK_FORM_DEFAULT_VALUES,
    mode: 'onChange',
  });

  const dueDate = watch('due_date');
  const assignedStaff = watch('assignments');
  const attachments = watch('attachments');

  const [createTask, { loading: isCreatingTask }] = useCreateTask({
    onCompleted: ({ createTask }) => {
      if (!createTask?.id) return;

      addToast({
        type: 'taskCreated',
        content: 'Task created successfully.',
      });

      const thisTaskPageRoute = ROUTE_PATHS.tasks;

      router.push(thisTaskPageRoute);
    },
    onError: () => {
      addToast({
        type: 'error',
        content: 'An error has occured whilst creating your task',
      });
    },
    refetchQueries: [
      { query: GetTasksDocument, variables: GET_TASKS_DEFAULT_VARIABLES },
    ],
  });

  const handleToggleAssignee = (assignees: IUser[]) => {
    setValue('assignments', assignees, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const handleAssignStaffModalDoneButtonClick = (
    shouldSendInvitationEmail: boolean
  ) => {
    setValue('shouldSendInvitationEmail', shouldSendInvitationEmail);
  };

  const handleRemoveAssignee = (user: IUser) => {
    setValue(
      'assignments',
      assignedStaff.filter((assignee) => assignee.id !== user.id),
      {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      }
    );
  };

  const handleAddAttachment = (attachment: IAttachment) => {
    const transformAttachment =
      transformUploadResponseToAttachmentListItem(attachment);

    setValue('attachments', [transformAttachment, ...attachments], {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const handleRemoveAttachment = (attachmentId: string) => {
    //TODO: call API to remove attachment in the BE
    setValue(
      'attachments',
      attachments.filter((attachment) => attachmentId !== attachment.id),
      {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      }
    );
  };

  const onSubmit = (formData: CreateTaskForm) => {
    createTask({
      variables: {
        taskType: 'retail_insight',
        fields: transformCreateTaskFormDataToAPIFields(formData),
      },
    });
  };

  useEffect(() => {
    register('assignments');
  }, [register]);

  return (
    <main className="px-4">
      <div className="mx-auto mb-32 mt-4 w-full max-w-screen-xl rounded-lg bg-white px-4 py-4 sm:p-10">
        <div className="mb-8 flex items-center justify-between">
          <h1 className="text-2xl font-bold">Create Task</h1>
          <button
            type="button"
            title="Return to tasks"
            aria-label="Return to tasks"
            className="hover:bg-gray-hover focus:bg-gray-hover z-10 inline-flex size-8 items-center  justify-center rounded-full p-1 disabled:cursor-wait sm:size-6"
            onClick={router.back}
            onKeyUp={({ key }) => {
              if (key === 'Enter') {
                router.back();
              }
            }}
          >
            <p className="sr-only">return to tasks</p>
            <CloseIcon light />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ul className="list-none">
            <TwistListItem
              icon={<TickIcon fillColor="#fff" />}
              iconBackgroundColor="bg-pink"
            >
              <FormTextInput
                {...register('title')}
                autoFocus
                label="Task Name *"
                placeholder="Write a task name"
                aria-invalid={errors.title ? 'true' : 'false'}
                error={errors.title?.message}
                icon={renderTaskFormFieldIcon(
                  <TickIcon fillColor="#fff" />,
                  isPhone
                )}
              />

              <div className="mt-4">
                <Controller
                  control={control}
                  name="priority"
                  render={({ field: { value, onChange } }) => (
                    <TaskPriority
                      priority={value ?? IPriorityLevelEnum.Medium}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </TwistListItem>

            <TwistListItem
              icon={<ExclamationIcon fillColor="#fff" />}
              iconBackgroundColor="bg-pink"
            >
              <FormTextArea
                {...register('description')}
                label="Information"
                placeholder="e.g. description of the task"
                aria-invalid={errors.description ? 'true' : 'false'}
                error={errors.description?.message}
                icon={renderTaskFormFieldIcon(
                  <ExclamationIcon fillColor="#fff" />,
                  isPhone
                )}
              />
            </TwistListItem>

            <TwistListItem
              icon={<StaffIcon fillColor="#fff" />}
              iconBackgroundColor="bg-pink"
              showStrip
            >
              <FormInputBase
                label="Assign To"
                name="assignments"
                sublabel="Select Staff members to assign this task to"
                error={errors.assignments?.message}
                icon={renderTaskFormFieldIcon(
                  <StaffIcon fillColor="#fff" />,
                  isPhone
                )}
              >
                <ul className="space-y-2">
                  {filterValidUsers(assignedStaff).map((staff) => (
                    <li key={staff.id}>
                      <UserListItem
                        user={staff}
                        handleToggle={handleRemoveAssignee}
                        isSelected
                      />
                    </li>
                  ))}
                </ul>

                <div className="mt-4 space-y-4">
                  {!isEmpty(assignedStaff) ? (
                    <Controller
                      control={control}
                      name="require_resolution"
                      render={({ field: { value, onChange } }) => (
                        <Checkbox
                          isSelected={value}
                          onChange={onChange}
                          isDisabled={isCreatingTask}
                        >
                          <span className="font-semibold">
                            Attachment required
                          </span>
                        </Checkbox>
                      )}
                    />
                  ) : null}

                  <Button
                    status="basic"
                    spacing="small"
                    onClick={assignStaffModal.open}
                    rounded="rounded-full"
                  >
                    Assign Staff
                  </Button>
                </div>
              </FormInputBase>
            </TwistListItem>

            {hasTagsFieldFlag ? (
              <TwistListItem
                icon={<TagIcon fillColor="#fff" />}
                iconBackgroundColor="bg-pink"
                showStrip
              >
                <Controller
                  control={control}
                  name="tags"
                  render={({ field: { value, onChange } }) => (
                    <Tags editable tags={value ?? []} onChange={onChange} />
                  )}
                />
              </TwistListItem>
            ) : null}

            <TwistListItem
              icon={<CalendarIcon fillColor="#fff" />}
              iconBackgroundColor="bg-pink"
              showStrip
            >
              <Controller
                control={control}
                name="due_date"
                render={({ field: fieldProps }) => {
                  return (
                    <FormDateTimePicker
                      label="Due Date"
                      minDate={new Date()}
                      icon={renderTaskFormFieldIcon(
                        <CalendarIcon fillColor="#fff" />,
                        isPhone
                      )}
                      error={errors.due_date?.message}
                      {...fieldProps}
                    />
                  );
                }}
              />
            </TwistListItem>

            {hasDueDateFlag && dueDate && (
              <TwistListItem
                icon={<RepeteIcon fillColor="#fff" />}
                iconBackgroundColor="bg-pink"
                showStrip
              >
                <Controller
                  control={control}
                  name="occurrence_manager"
                  render={({ field: { onChange } }) => {
                    return (
                      <DueDateRepeat
                        dueDate={dueDate}
                        onChange={onChange}
                        error={{
                          end_date:
                            errors.occurrence_manager?.end_date?.message?.toString(),
                        }}
                      />
                    );
                  }}
                />
              </TwistListItem>
            )}

            <TwistListItem
              icon={<FileIcon fillColor="#fff" />}
              iconBackgroundColor="bg-pink"
              showStrip={false}
            >
              <FormInputBase
                label="File and image attachments"
                name="attachments"
                sublabel="Add any files or images to this task"
                error={errors.attachments?.message}
                icon={renderTaskFormFieldIcon(
                  <FileIcon fillColor="#fff" />,
                  isPhone
                )}
              >
                <AttachmentsList
                  isEditable
                  attachments={attachments}
                  onUploadComplete={handleAddAttachment}
                  onRemoveComplete={handleRemoveAttachment}
                />
              </FormInputBase>
            </TwistListItem>
          </ul>

          <div className="xs:grid hidden place-content-center">
            <Button
              type="submit"
              status="primary"
              disabled={!isDirty || !isValid || isCreatingTask}
              loading={isCreatingTask}
              rounded="rounded-full"
            >
              Create Task
            </Button>
          </div>

          {/* Mobile floating button */}
          <WidgetBar>
            <div className="xs:hidden">
              <Button
                type="submit"
                status="primary"
                disabled={!isDirty || !isValid || isCreatingTask}
                loading={isCreatingTask}
                rounded="rounded-full"
                iconLeft={<PlusIcon light fillColor="currentColor" />}
              >
                Create Task
              </Button>
            </div>
          </WidgetBar>
        </form>
      </div>

      {assignStaffModal.isOpen && (
        <AssignStaffModal
          modalState={assignStaffModal}
          assignedStaff={assignedStaff}
          onChange={handleToggleAssignee}
          onDone={handleAssignStaffModalDoneButtonClick}
        />
      )}
    </main>
  );
};
