import { useState } from 'react';
import { QueryPagination } from './api';

/**
 * Provides pagination logic for the front-end
 *
 * @example
 * export const useQueryCustomHook = () => {
 *   const [page, withPagination] = useWithPagination();
 *
 *   const query = useQuery({
 *     queryKey: ['some-paginated-query', page],
 *     queryFn: fetchPaginatedData,
 *   });
 *
 *   return {
 *     ...query,
 *     ...withPagination(query.data?.pagination),
 *   };
 * };
 */
export const useWithPagination = () => {
  let [page, setPage] = useState(1); // TODO[high]: what about defaults?

  const getPagination = (queryPagination: QueryPagination | undefined) => {
    let totalPages = queryPagination?.totalPages || 0;

    return {
      pagination: {
        page,
        totalPages,
        isLastPage: totalPages === page,
        previous: () => setPage((currPage) => Math.max(1, currPage - 1)),
        next: () => setPage((currPage) => Math.min(totalPages, currPage + 1)),
        goTo: (newPage: number) =>
          setPage(Math.min(Math.max(1, newPage), totalPages)),
      },
    };
  };

  return [page, getPagination] as const;
};
