import { dtoToActivity } from './dtoToActivity';
import ActivityAPI, {
  ActivitiesByThreadIdParams,
  ActivitiesByUserIdParams,
} from '../api/api';

// TODO[high]: this module should probably be made into a class service for testabilty

export const getUserActivities = async (
  userId: string,
  params: ActivitiesByUserIdParams
) => {
  const { data, pagination } = await ActivityAPI.fetchActivitiesByUserId(
    userId,
    params
  );

  const activities = data.map(dtoToActivity);

  return { activities, pagination };
};

export const getActivitiesByThreadId = async (
  threadId: string,
  params: ActivitiesByThreadIdParams
) => {
  const { data, pagination } = await ActivityAPI.fetchActivitiesByThreadId(
    threadId,
    params
  );

  const activities = data.map(dtoToActivity);

  return { activities, pagination };
};

export default {
  getUserActivities,
  getActivitiesByThreadId,
};
